const ENVIRONMENTS = {
  PRODUCTION: "production",
  STAGING: "staging",
};

const MODE = ENVIRONMENTS.PRODUCTION;

const stagingConfig = {
  stgApiUrl: "https://stgapi.ikhair.net",
  ikhair: "https://api2.ikhair.net",
  ikhairstg: "https://stgapi.ikhair.net",
  kheerom: "https://api-om.ikhair.net",
  jonation: "https://jonationapi.ikhair.net",
  prodApibagisUrl: "https://bagisapi.instabackend.io",
  kongPassword: "n7s7id63",
  appgainServer: "https://feapi.appgain.io",
  appApiKey: "ddfa1b658abf0b2f8a6e87d6efcfec7c3d2b4980850b21237febae38664130a6",
  appId: "ikharstga",
  appBackend:
    "https://appbackend.appgain.io/5e402b30970b2b0009d1221b/ikharstga",
  accountId: "5e402b30970b2b0009d1221b",
  subdomain: "ikharstga",
  parseMasterKey:
    "MASTER-5de399a9d4c40ae9fd03883547fa4e636b1f75f0dcf7d4ac754aea9e3ff3526d",
  ACCESS_KEY_ID: "AKIARUMASEGMPTTKK6AR",
  SECRET_ACCESS_KEY: "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",
  REGION: "us-east-1",
  suitId: "5e403f53970b2b000b92de74",
  JalilahSuitId: "",
};

const productionConfig = {
  stgApiUrl: "https://stgapi.ikhair.net",
  ikhair: "https://api2.ikhair.net",
  ikhairstg: "https://stgapi.ikhair.net",
  kheerom: "https://api-om.ikhair.net",
  jonation: "https://jonationapi.ikhair.net",
  prodApibagisUrl: "https://bagisapi.instabackend.io",
  kongPassword: "n7s7id63",
  appgainServer: "https://feapi.appgain.io",
  appApiKey: "bc29b9c3cbdc867b19863efef95a27e1408a59e212809db0f2e20bbba60eff1b",
  appId: "APP-57931CC-7061-52D4-B5EA-356F2352B939",
  appBackend: "https://appbackend.appgain.io/5864ef5223fcb95c7a99a156/ikhair",
  accountId: "5864ef5223fcb95c7a99a156",
  subdomain: "ikhair",
  parseMasterKey:
    "MASTER-5de399a9d4c40ae9fd03883547fa4e636b1f75f0dcf7d4ac754aea9e3ff3526d",
  ACCESS_KEY_ID: "AKIARUMASEGMPTTKK6AR",
  SECRET_ACCESS_KEY: "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",
  REGION: "us-east-1",
  suitId: "57677ee19908f42180350a27",
  JalilahSuitId: "",
};

const config = MODE === ENVIRONMENTS.STAGING ? stagingConfig : productionConfig;

export { config };
